import React from 'react'
import '../assets/Logo.css';
import Logo from '../assets/logo512.png';

const Aiadlogo = () => {
  return (



<div className="md:flex hidden main-section-background-logocss bg-gradient-to-b from-[#000000] via-[#00324a] to-[#000000] md:w-max-[768px] lg:w-max[1024px] xl:w-max-[1280px] justify-center items-center">


        <div className='flex justify-center pt-[80px] lg:pt-[250px] '>
        
       </div>
        <div>
        <div className='flex justify-center items-center w-[450px] h-[450px] rounded-[450px] bg-gradient-to-b from-[#fb04f7] to-[#3c13f2]'>
        <div className='flex justify-center items-center w-[430px] h-[430px] rounded-[430px] bg-gradient[#555444] bg-[#000000]'>
<div className='flex justify-center items-center w-[420px] h-[420px] rounded-[420px] bg-gradient-to-r from-[#9404fb] to-[#692ff1]'>
    <div className='flex justify-center items-center w-[390px] h-[390px] rounded-[390px] bg-black'>
        <div className='flex animate-pulse absolute-//#endregion justify-center items-center w-[370px] h-[370px] rounded-[370px] bg-gradient-to-b from-[#9d03fd] to-[#693bc5]'>
            <div className='flex justify-center items-center w-[320px] h-[320px] rounded-[320px] bg-gradient[#555444] bg-black'>
                    <div><h2 className=' items-center justidy-between text-white text-8xl font-bold '><img src={Logo} alt='AIAD' /></h2></div>
            </div>
</div>
</div>
        </div>

    </div>

</div>

        </div>


        </div>

   

    
  )
}

export default Aiadlogo;