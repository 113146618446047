import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Slidercards.css'; // Custom CSS for Swiper pagination

function Slidercards() {
  return (
    <div className="hidden sm:block screen w-full justify-center items-center border-none p-[25px] mt-[-500px]">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={3}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
        {/* 1st Slide */}
        <SwiperSlide>
          <div className='flex justify-center items-center py-10 md:p-1'>
            <div className="md:max-w-sm md:h-[300px] p-7 bg-gradient-to-r from-[#030303] to-[#012f3a] border-8 border-[#252525] rounded-3xl">
              <h2 className="mb-7 text-3xl font-bold tracking-tight text-white">AIAD Golden Pass NFT</h2>
              <p className="text-1xl mb-3 font-normal text-gray-200">
                Your gateway to unlocking exclusive utilities within the AIAD ecosystem. As one of only 111 limited NFTs, holders will have access to unique benefits, advanced tools, and premium features across the AIAD platform.
              </p>
            </div>
          </div>
        </SwiperSlide>

        {/* 2nd Slide */}
        <SwiperSlide>
          <div className='flex justify-center items-center py-10 md:p-1'>
            <div className="md:max-w-sm h-full md:h-[300px] p-7 bg-gradient-to-r from-[#030303] to-[#012f3a] border-8 border-[#252525] rounded-3xl">
              <h2 className="mb-7 text-3xl font-bold tracking-tight text-white">AI-Powered Innovation</h2>
              <p className="text-1xl mb-3 font-normal text-gray-200">
              AIAD is introducing a groundbreaking shift in the digital advertising realm with its AI-driven 
platform, built on the robust Solana blockchain. This pioneering approach is not just a 
technological advancement; it's a redefinition of advertising norms.
              </p>
            </div>
          </div>
        </SwiperSlide>

        {/* 3rd Slide */}
        <SwiperSlide>
        <div className='flex justify-center items-center py-10 md:p-1'>
            <div className="md:max-w-sm h-full md:h-[300px] p-7 bg-gradient-to-r from-[#030303] to-[#012f3a] border-8 border-[#252525] rounded-3xl">
              <h2 className="mb-7 text-3xl font-bold tracking-tight text-white">Transparency</h2>
              <p className="text-1xl mb-3 font-normal text-gray-200">
              This innovation is set to redefine the advertising landscape, making it more 
accessible, transparent, and responsive to the dynamic needs of the market.
              </p>
            </div>
          </div>
        </SwiperSlide>

        {/* 4th Slide */}
        <SwiperSlide>
          <div className='flex justify-center items-center py-10 md:p-1'>
            <div className="md:max-w-sm h-full md:h-[300px] p-7 bg-gradient-to-r from-[#030303] to-[#012f3a] border-8 border-[#252525] rounded-3xl">
              <h2 className="mb-7 text-3xl font-bold tracking-tight text-white">Advertisers and Publishers</h2>
              <p className="text-1xl mb-3 font-normal text-gray-200">
                A pivotal development in digital advertising, bringing new levels of efficiency, cost-effectiveness, and direct engagement between advertisers and publishers.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Slidercards;
