import React from 'react'

function Banner() {
  return (
    <div className='md:flex items-center justify-center w-full md:h-[20px]  bg-gradient-to-tr from-[#04bebe] to-[#00ffdd] hover:bg-gradient-to-bl hover:from--[#00ffdd] hover:to-[#04bebe] p-1 text-center bg-opacity-25'>
        <p className='md:font-bold text-[#000000] md:text-[15px] text-[8px]'>
        ⚠️ AIAD token is not out yet! Watch out for scammers. Only check our official channels. 
        </p>

    </div>
  )
}

export default Banner