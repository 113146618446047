import React from 'react'

function Goldenpassdescription() {
  return (
    <div className='flex justify-center items-center text-center md:mx-[1px] mx-[25px] pt-32 md:pb-3'>
    <div className="md:w-1/2 md:h-[250px] p-7 bg-transparent border-1 border-[#a04f26]  rounded-3xl bg-gradient-to-br from-[#000606] via-[#842c01] to-[#010701]">
      <h2 className="mb-7 text-3xl font-bold tracking-tight text-[#d46d2d]">AIAD Golden Pass</h2>
      <p className="text-xl mb-3 font-normal text-[#aaabaa] text-center">
      The AIAD Golden Pass is your exclusive ticket to premium benefits in the AIAD ecosystem. Limited to just 111 NFTs, holders will gain early access to groundbreaking advertising tools, exclusive community perks, and much more.
      </p>
    </div>
  </div>
  )
}

export default Goldenpassdescription