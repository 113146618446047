import React from 'react';
import Aiadglimpseimage from '../assets/Aiadglimpseimage.png';


function Aiadglimpse() {
  return (
    <div className="screen bg-gradient-to-b from-black via-[#004243] to-[#000000] w-full justify-center items-center border-none py-[5px] px-[25px]">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center py-10 md:p-1 '>
                      
            <img className='shadow- md:h-max md:max-w-xl border-none shadow transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-102 animate-none' src={Aiadglimpseimage} alt='hg'></img>
                      
        </div>




        <div className='flex justify-center items-center md:py-10'>
        <div className='flex md:py-10 pt-2 pb-20 '>
          <div className="md:h-[300px] max-w-lg p-7 bg-gradient-to-r from-[#030303] to-[#152716] border border-gray-200 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-white">Advertisers and publishers</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            a pivotal development in digital advertising. It brings a 
new level of efficiency, cost-effectiveness, and direct engagement between advertisers and 
publishers. This innovation is set to redefine the advertising landscape, making it more 
accessible, transparent, and responsive to the dynamic needs of the market.

            </p>
            <br />
            
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Aiadglimpse;
