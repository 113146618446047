import React from 'react';
import Plantimage from '../assets/Utility.png'

function Tokencard() {
  return (
    <div className="screen w-auto justify-center items-center border-none mx-[3px] mt-24">


<div className='flex justify-center items-center md:mt-[10px]  md:p-10 p-[25px]'>
          <div className="md:grid grid-cols-2 max-w-lg p-7 bg-gradient-to-r from-[#030303] to-[#012f3a] border border-[#102d41] rounded-3xl shadow transition duration-20 ease-in-out transform hover:-translate-y-1 hover:scale-105 ">
            <div>
            <div className='flex md:hidden mb-10'><img src={Plantimage} alt='AIAD Tokenomics' className='flex justify-center items-center w-full'></img></div>
              <h2 className="mb-5 text-3xl font-bold tracking-tight text-white md:mb-9">Tokenomics of $AIAD</h2>
              <p className="text-1xl mb-3 font-normal text-gray-200">
            AIAD's tokenomics strategy is designed to ensure the long-term viability and growth of the 
platform. The distribution of AIAD tokens is as follows:
            </p>
            </div>
            <div className='hidden md:flex'><img src={Plantimage} alt='AIAD Tokenomics' className='flex justify-center items-center w-full'></img></div>
           
            
          </div>
          
        </div>


    </div>




  )
}

export default Tokencard