import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Slidercards.css'; // Custom CSS for Swiper pagination
import Goldenpassaiadnftimage from '../assets/AIAD_Golden_Pass_AIAD_NFT.png';
import Goldenpassaiadnftimagebw from '../assets/AIAD_Golden_Pass_NFT._Teaser_BW.png';


function Aiadgoldenpassnft() {
   
        return (
          <div className="hidden md:block screen bg-gradient-to-b from-black via-[#012506] to-[#000000] w-full justify-center items-center border-none pb-[200px] pt-14 px-[25px]">
          
            
            
            <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={5}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
              {/* 1st Slide */}
              <SwiperSlide>
                <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimage} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>
      
              {/* 2nd Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimagebw} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>
      
              {/* 3rd Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimage} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>
      
              {/* 4th Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimagebw} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>

              {/* 5th Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimage} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>

                {/* 6th Slide */}
                <SwiperSlide>
                <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimagebw} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>


            </Swiper>
          </div>
        );
      }

export default Aiadgoldenpassnft;
