import React from 'react';

const data = {
  'DEX Listing': 50,
  'CEX Listing': 21,
  'Team': 3,
  'Marketing': 9,
  'Development': 10,
  'Reserved': 7,
};

const Aiadokenomics = () => {
  const maxValue = Math.max(...Object.values(data));
  

  return (
    <div id="aiadtokenomics" className="md:py-20 py-[20px] md:px-96 px-[25px] text-[#a5f7e5] font-bold transition duration-100 ease-in-out transform hover:-translate-y-1 hover:scale-105 ">
      {Object.entries(data).map(([key, value]) => (
        
        <div key={key} className="flex items-center m-5">
          <span className="w-32">{key}</span>
          <div className="flex-1 h-6 relative mx-20">
            <div className="flex inset-0 bg-gray-200 rounded"></div>
            <div
              className="absolute inset-0 bg-gradient-to-r from-green-400 to-blue-500 rounded"
              style={{ width: `${(value / maxValue) * 100}%` }}
            ></div>
          </div>
          <span>{value}%</span>
        </div>
      ))}
    </div>
  );
};

export default Aiadokenomics;